<template>
    <div class="integralPage overflow ">
        <div class="integralText">
            <p class="integralTop flex flexCenter"><img src="../assets/action.png" /><span>积分充值</span><img
                    src="../assets/ac_right.png" /></p>
            <input class="integralInput" placeholder="请输入积分卡" v-model="card" />
            <!-- <div class="integralBtns flex flexCenter"> -->
                <p class="integralBtn baseMax textCenter cursor" @click="rechangeMethod"><i class="el-icon-loading"
                        v-if="isLoading"></i>立即充值</p>
                <p class="integralBtn baseMax textCenter cursor" @click="isCodeShow=true">购买卡密</p>
            <!-- </div> -->
            <!-- <p class="integralBtn baseMax textCenter cursor" @click="rechangeMethod"><i class="el-icon-loading"
                    v-if="isLoading"></i>立即充值</p> -->

        </div>
        <!-- 购买卡密二维码start -->
        <el-dialog title="联系TA购买积分卡" :visible.sync="isCodeShow" top="35vh" width="30%" custom-class="codeDialog" :close-on-click-modal="false">
            <MyInvitationCode type="myAgent"/>
        </el-dialog>
        <!-- 购买卡密二维码end -->
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import { Message, MessageBox } from 'element-ui';
    import api from "../../http/api";
    import { copyMethod } from "../../utils";

    export default {
        data() {
            return {
                isCodeShow: false,  //是否展示二维码
                isLoading: false,//loading
                card: '',//积分卡密  
            }
        },
        components: {
            MyInvitationCode:() => import("../components/myInvitationCode"),
        },
        computed: {
            ...mapState(['agentSetting']), //
        },
        activated() {
           this.card=''
           // console.log('agentSetting!!!--->', this.agentSetting)
        },
        created() {
            //console.log('agentSetting--->', this.agentSetting)
        },

        methods: {
            //一键复制
            copyFun(text){
                copyMethod(text)
            },
            //确认充值
            async sureMethod() {
                let that = this;
                let result = await api.getReceive({
                    card_secret_key: that.card
                })
                that.isLoading = false;
                Message({
                    message: result.code == 200 ? '充值成功~' : result.msg,
                    type: result.code == 200 ? 'success' : 'error',
                    duration : 8000
                });
                if (result.code == 200) {
                    that.card = '';
                    that.$store.commit('changeRechange', { code: false, msg: '' })
                    setTimeout(() => {
                        that.$parent.$parent.changeMenu('wxcollect');
                        that.$parent.$parent.getInfo();
                        // that.$emit('changeMenu','wxcollect') 
                    }, 2000)
                }
            },
            //立即充值
            async rechangeMethod() {
                let that = this;
                if (!that.card) {
                    Message({
                        message: '请输入积分卡卡密~',
                        type: 'error'
                    });
                    return
                }
                that.card = that.card.replace(/^\s+|\s+$/g, "") //去除前后空格
                that.isLoading = true;
                 that.sureMethod()
                //return;
                //已充过的积分卡不要再次提示，暂时去掉
                // let isDialog = await api.getIsRechange()
                // console.log('isDialog', isDialog)
                // if (isDialog.code == 200 && isDialog.response.is_effective) {
                //     that.isLoading = false;
                //     MessageBox.confirm(isDialog.response.msg, '提示', {
                //         confirmButtonText: '确定',
                //         cancelButtonText: '取消',
                //         type: 'warning'
                //     }).then((action) => {
                //         if (action == 'confirm') {
                //             that.sureMethod()
                //         }
                //     })
                // } else {
                //     that.sureMethod()
                // }

                // console.log('result', result)
            }
        }
    }
</script>
<style lang="less" scoped>
    .integralPage {
        width: 100%;
        height: 100%;
        background: url(../assets/recharge.jpg) no-repeat;
        background-size: 100% 100%;

        .integralText {
            width: 350px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .integralInput {
                width: 100%;
                line-height: 48px;
                background: #FFFFFF;
                border: 1px solid #DCDCDC;
                text-align: center;
                margin: 28px 0 28px 0;
            }

            .integralBtn {
            width:100%;
                /* width: 140px; */
                line-height: 35px;
                background: #FED46E;
                margin: auto;

                i {
                    margin-right: 4px;
                }
            }
            .integralBtn:last-child{
                margin-top:10px;
            }
            .integralTop {
                font-size: 22px;

                img {
                    width: 24px;
                    margin: 0px 14px;
                }
            }
        }

        .codeContent {
            img {
                width: 100px;
                height:100px;
                background:#efefef;
                margin:20px auto 10px auto;
            }

            .codeBtns {
                p{
                    text-align:center;
                }
               .copyBtnItem{
                    width: 100px;
                    line-height: 30px;
                    text-align: center;
                    background: #409eff;
                    color: #fff;
                    border-radius: 4px;
                    margin: 10px auto;
                }
              
            }
        }
    }
    /deep/ .codeDialog  .el-dialog__header{
    text-align:center;
  }
    
</style>